import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Row, Col, Alert } from 'react-bootstrap';
import { authService } from '../../services/api/authService';

const Login = () => {
  const [loginOptions, setLoginOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchLoginOptions();
  }, []);

  const fetchLoginOptions = async () => {
    try {
      setLoading(true);
      setError(null);
      const options = await authService.getLoginOptions();
      setLoginOptions(options);
    } catch (err) {
      setError('Failed to load login options');
      console.error('Error fetching login options:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = (loginUri) => {
    authService.initiateLogin(loginUri);
  };

  if (loading) {
    return (
      <Container>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col md={6} lg={4} className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col md={6} lg={4}>
          <Card>
            <Card.Body className="text-center">
              <h2 className="mb-4">Admin Login</h2>
              {error && (
                <Alert variant="danger" className="mb-4">
                  {error}
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    className="ms-2"
                    onClick={fetchLoginOptions}
                  >
                    Retry
                  </Button>
                </Alert>
              )}
              {loginOptions.map((option, index) => (
                <Button
                  key={index}
                  variant="primary"
                  onClick={() => handleLogin(option.loginUri)}
                  className="w-100 mb-2"
                >
                  Login with {option.label}
                </Button>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;