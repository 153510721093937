import axiosInstance from './axios.config';

export const authService = {
  checkAuth: async () => {
    try {
      const response = await axiosInstance.get('/admin/v1/users/me');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getLoginOptions: async () => {
    try {
      const response = await axiosInstance.get('/login-options');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch login options:', error);
      throw error;
    }
  },

  initiateLogin: (loginUri) => {
    // Redirect to the login URI directly as we're using Spring Cloud Gateway
    window.location.href = loginUri;
  },

  logout: async () => {
    try {
      // First, logout from the BFF
      await axiosInstance.post('/logout');

      // Clear any local storage or session storage if you're using any
      localStorage.clear();
      sessionStorage.clear();

      // Redirect to login page
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout error:', error);
      // Even if there's an error, try to redirect to login
      window.location.href = '/login';
    }
  }
};