import { apiService } from './apiService';

export const newsService = {
  getNews: (page = 0, size = 10) => {
    return apiService.get(`/v1/news?page=${page}&size=${size}`);
  },

  getNewsById: (id) => {
    return apiService.get(`/v1/news/${id}`);
  },

  createNews: (newsData) => {
    return apiService.post('/v1/news', newsData);
  },

  deleteNews: (id) => {
    return apiService.delete(`/v1/news/${id}`);
  }
};
