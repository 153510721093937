import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Card, Alert, Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { comicService } from '../../services/api/comicService';
import ImagePreview from '../../components/common/ImagePreview';
import { format } from 'date-fns';

const ComicList = () => {
  const [comics, setComics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchComics = useCallback(async () => {
    try {
      setLoading(true);
      const response = searchQuery
        ? await comicService.searchComics(searchQuery)
        : await comicService.getComics();
      setComics(response.content);
    } catch (err) {
      setError('Failed to fetch comics');
      console.error('Error fetching comics:', err);
    } finally {
      setLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchComics();
  }, [fetchComics]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this comic?')) {
      try {
        await comicService.deleteComic(id);
        await fetchComics();
      } catch (err) {
        console.error('Error deleting comic:', err);
      }
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchComics();
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Comics</h1>
        <Button as={Link} to="/comics/create" variant="primary">
          Add Comic
        </Button>
      </div>

      <Card className="mb-4">
        <Card.Body>
          <Form onSubmit={handleSearch}>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Search comics..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button type="submit" variant="outline-secondary">
                Search
              </Button>
            </InputGroup>
          </Form>
        </Card.Body>
      </Card>

      {error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Card>
          <Card.Body>
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Preview</th>
                  <th>Title</th>
                  <th>Created At</th>
                  <th>Views</th>
                  <th>Reactions</th>
                  <th>Comments</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {comics.map(comic => (
                  <tr key={comic.id}>
                    <td>
                      <ImagePreview
                        url={comic.mainImageUrl}
                        alt={comic.title}
                        size="50px"
                      />
                    </td>
                    <td>{comic.title}</td>
                    <td>{format(new Date(comic.createdAt), 'dd/MM/yyyy')}</td>
                    <td>{comic.viewsCount}</td>
                    <td>{comic.reactionsCount}</td>
                    <td>{comic.commentsCount}</td>
                    <td>
                      <Button
                        as={Link}
                        to={`/comics/${comic.id}`}
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                      >
                        View
                      </Button>
                      <Button
                        as={Link}
                        to={`/comics/${comic.id}/edit`}
                        variant="outline-secondary"
                        size="sm"
                        className="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDelete(comic.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ComicList;
