import { apiService } from './apiService';

export const comicService = {
  // Comic methods
  getComics: (page = 0, size = 10, sort = 'createdAt,desc') => {
    return apiService.get(`/v1/comics?page=${page}&size=${size}&sort=${sort}`);
  },

  getComicById: (id) => {
    return apiService.get(`/v1/comics/${id}`);
  },

  searchComics: (query, page = 0, size = 10) => {
    return apiService.get(`/v1/comics/search?query=${encodeURIComponent(query)}&page=${page}&size=${size}`);
  },

  createComic: (comic) => {
    return apiService.post('/v1/comics', comic);
  },

  updateComic: (id, comic) => {
    return apiService.put(`/v1/comics/${id}`, comic);
  },

  deleteComic: (id) => {
    return apiService.delete(`/v1/comics/${id}`);
  },

  // Chapter methods
  getComicChapters: (comicId, page = 0, size = 10) => {
    return apiService.get(`/v1/comics/${comicId}/chapters?page=${page}&size=${size}`);
  },

  getComicChapter: (comicId, chapterId) => {
    return apiService.get(`/v1/comics/${comicId}/chapters/${chapterId}`);
  },

  createChapter: (comicId, chapter) => {
    return apiService.post(`/v1/comics/${comicId}/chapters`, chapter);
  },

  updateChapter: (comicId, chapterId, chapter) => {
    return apiService.put(`/v1/comics/${comicId}/chapters/${chapterId}`, chapter);
  },

  deleteChapter: (comicId, chapterId) => {
    return apiService.delete(`/v1/comics/${comicId}/chapters/${chapterId}`);
  }
};
