import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { comicService } from '../../../services/api/comicService';

const ChapterForm = () => {
  const { comicId, chapterId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    index: 0,
    previewImageUrl: '',
    chapterImagePresignedUrl: ''
  });

  const fetchChapter = useCallback(async () => {
    try {
      setLoading(true);
      const chapter = await comicService.getComicChapter(comicId, chapterId);
      setFormData(chapter);
    } catch (err) {
      setError('Failed to fetch chapter');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, [comicId, chapterId]);

  useEffect(() => {
    if (chapterId) {
      fetchChapter();
    }
  }, [chapterId, fetchChapter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (chapterId) {
        await comicService.updateChapter(comicId, chapterId, formData);
      } else {
        await comicService.createChapter(comicId, formData);
      }
      navigate(`/comics/${comicId}`);
    } catch (err) {
      setError('Failed to save chapter');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Body>
        <h2 className="mb-4">{chapterId ? 'Edit' : 'Create'} Chapter</h2>
        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Chapter Index</Form.Label>
            <Form.Control
              type="number"
              value={formData.index}
              onChange={e => setFormData(prev => ({ ...prev, index: parseInt(e.target.value) }))}
              required
              min="0"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Preview Image URL</Form.Label>
            <Form.Control
              type="url"
              value={formData.previewImageUrl}
              onChange={e => setFormData(prev => ({ ...prev, previewImageUrl: e.target.value }))}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Chapter Image URL</Form.Label>
            <Form.Control
              type="url"
              value={formData.chapterImagePresignedUrl}
              onChange={e => setFormData(prev => ({ ...prev, chapterImagePresignedUrl: e.target.value }))}
              required
            />
          </Form.Group>

          <div className="d-flex justify-content-end gap-2">
            <Button
              variant="secondary"
              onClick={() => navigate(`/comics/${comicId}`)}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  {chapterId ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                chapterId ? 'Update Chapter' : 'Create Chapter'
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChapterForm;
