import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Card, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { galleryService } from '../../services/api/galleryService';
import ImagePreview from '../../components/common/ImagePreview';

const GalleryList = () => {
  const [galleries, setGalleries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGalleries = useCallback(async () => {
    try {
      setLoading(true);
      const response = await galleryService.getGalleries();
      setGalleries(response.content);
    } catch (err) {
      setError('Failed to fetch galleries');
      console.error('Error fetching galleries:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchGalleries();
  }, [fetchGalleries]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this gallery?')) {
      try {
        await galleryService.deleteGallery(id);
        await fetchGalleries();
      } catch (err) {
        console.error('Error deleting gallery:', err);
      }
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Galleries</h1>
        <Button as={Link} to="/galleries/create" variant="primary">
          Add Gallery
        </Button>
      </div>

      {error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Card>
          <Card.Body>
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Preview</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Images Count</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {galleries.map(gallery => (
                  <tr key={gallery.id}>
                    <td>
                      <ImagePreview
                        url={gallery.mainImageUrl}
                        alt={gallery.title}
                        size="50px"
                      />
                    </td>
                    <td>{gallery.title}</td>
                    <td>{gallery.description}</td>
                    <td>{gallery.images?.length || 0}</td>
                    <td>
                      <Button
                        as={Link}
                        to={`/galleries/${gallery.id}`}
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                      >
                        View
                      </Button>
                      <Button
                        as={Link}
                        to={`/galleries/${gallery.id}/edit`}
                        variant="outline-secondary"
                        size="sm"
                        className="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDelete(gallery.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default GalleryList;
