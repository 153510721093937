import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Alert, Row, Col, Table } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { comicService } from '../../services/api/comicService';
import ImagePreview from '../../components/common/ImagePreview';
import { format } from 'date-fns';

const ComicView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [comic, setComic] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchComic = useCallback(async () => {
    try {
      const comicData = await comicService.getComicById(id);
      const chaptersData = await comicService.getComicChapters(id);
      setComic(comicData);
      setChapters(chaptersData.content);
    } catch (err) {
      setError('Failed to fetch comic details');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchComic();
  }, [fetchComic]);

  const handleDeleteComic = async () => {
    if (window.confirm('Are you sure you want to delete this comic?')) {
      try {
        await comicService.deleteComic(id);
        navigate('/comics');
      } catch (err) {
        console.error('Error deleting comic:', err);
      }
    }
  };

  const handleDeleteChapter = async (chapterId) => {
    if (window.confirm('Are you sure you want to delete this chapter?')) {
      try {
        await comicService.deleteChapter(id, chapterId);
        fetchComic(); // Refresh the chapter list
      } catch (err) {
        console.error('Error deleting chapter:', err);
      }
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) return <Alert variant="danger">{error}</Alert>;
  if (!comic) return <Alert variant="warning">Comic not found</Alert>;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>{comic.title}</h1>
        <div>
          <Button
            variant="primary"
            onClick={() => navigate(`/comics/${id}/edit`)}
            className="me-2"
          >
            Edit Comic
          </Button>
          <Button
            variant="danger"
            onClick={handleDeleteComic}
            className="me-2"
          >
            Delete Comic
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => navigate('/comics')}
          >
            Back to List
          </Button>
        </div>
      </div>

      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col md={4}>
              <ImagePreview 
                url={comic.mainImageUrl} 
                alt={comic.title}
                size="100%"
              />
            </Col>
            <Col md={8}>
              <h5>Details</h5>
              <p><strong>Created:</strong> {format(new Date(comic.createdAt), 'dd/MM/yyyy HH:mm')}</p>
              <p><strong>Views:</strong> {comic.viewsCount}</p>
              <p><strong>Featured:</strong> {comic.featured ? 'Yes' : 'No'}</p>
              <h5>Annotation</h5>
              <p>{comic.annotation}</p>
              <h5>Authors</h5>
              <ul>
                {comic.authors?.map(author => (
                  <li key={author.id}>{author.name}</li>
                ))}
              </ul>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Chapters</h3>
        <Button
          variant="primary"
          onClick={() => navigate(`/comics/${id}/chapters/create`)}
        >
          Add Chapter
        </Button>
      </div>

      <Card>
        <Card.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th>Preview</th>
                <th>Index</th>
                <th>Views</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {chapters.map(chapter => (
                <tr key={chapter.id}>
                  <td>
                    <ImagePreview
                      url={chapter.previewImageUrl}
                      alt={`Chapter ${chapter.index}`}
                      size="50px"
                    />
                  </td>
                  <td>{chapter.index}</td>
                  <td>{chapter.viewsCount || 0}</td>
                  <td>
                    <Button
                      as={Link}
                      to={`/comics/${id}/chapters/${chapter.id}`}
                      variant="outline-primary"
                      size="sm"
                      className="me-2"
                    >
                      View
                    </Button>
                    <Button
                      as={Link}
                      to={`/comics/${id}/chapters/${chapter.id}/edit`}
                      variant="outline-secondary"
                      size="sm"
                      className="me-2"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleDeleteChapter(chapter.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default ComicView;
