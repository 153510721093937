import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Card, Alert, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { galleryService } from '../../services/api/galleryService';
import ImagePreview from '../../components/common/ImagePreview';

const GalleryForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    mainImageUrl: '',
    images: []
  });

  const fetchGallery = useCallback(async () => {
    try {
      setLoading(true);
      const gallery = await galleryService.getGalleryById(id);
      setFormData(gallery);
    } catch (err) {
      setError('Failed to fetch gallery');
      console.error('Error fetching gallery:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchGallery();
    }
  }, [id, fetchGallery]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (id) {
        await galleryService.updateGallery(id, formData);
      } else {
        await galleryService.createGallery(formData);
      }
      navigate('/galleries');
    } catch (err) {
      setError(`Failed to ${id ? 'update' : 'create'} gallery`);
      console.error(`Error ${id ? 'updating' : 'creating'} gallery:`, err);
    } finally {
      setLoading(false);
    }
  };

  const handleImageAdd = () => {
    setFormData(prev => ({
      ...prev,
      images: [...prev.images, { previewImageUrl: '', fullImageUrl: '', tags: [] }]
    }));
  };

  const handleImageRemove = (index) => {
    setFormData(prev => ({
      ...prev,
      images: prev.images.filter((_, idx) => idx !== index)
    }));
  };

  const handleImageChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      images: prev.images.map((img, idx) => 
        idx === index ? { ...img, [field]: value } : img
      )
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formData.images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormData(prev => ({ ...prev, images: items }));
  };

  return (
    <Card>
      <Card.Body>
        <h2 className="mb-4">{id ? 'Edit' : 'Create'} Gallery</h2>
        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.title}
                  onChange={e => setFormData(prev => ({ ...prev, title: e.target.value }))}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Main Image URL</Form.Label>
                <div className="d-flex gap-2">
                  <Form.Control
                    type="url"
                    value={formData.mainImageUrl}
                    onChange={e => setFormData(prev => ({ ...prev, mainImageUrl: e.target.value }))}
                    required
                  />
                  {formData.mainImageUrl && (
                    <ImagePreview url={formData.mainImageUrl} alt="Main preview" />
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.description}
              onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
              required
            />
          </Form.Group>

          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>Gallery Images</h4>
              <Button
                variant="outline-primary"
                onClick={handleImageAdd}
                type="button"
              >
                Add Image
              </Button>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="gallery-images">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {formData.images.map((image, index) => (
                      <Draggable
                        key={index}
                        draggableId={`image-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="mb-3"
                          >
                            <Card.Body>
                              <div {...provided.dragHandleProps} className="mb-2">
                                <i className="bi bi-grip-vertical me-2"></i>
                                Image #{index + 1}
                              </div>
                              <Row>
                                <Col md={5}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Preview Image URL</Form.Label>
                                    <div className="d-flex gap-2">
                                      <Form.Control
                                        type="url"
                                        value={image.previewImageUrl}
                                        onChange={e => handleImageChange(index, 'previewImageUrl', e.target.value)}
                                        required
                                      />
                                      <ImagePreview 
                                        url={image.previewImageUrl} 
                                        alt={`Preview ${index + 1}`}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col md={5}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Full Image URL</Form.Label>
                                    <div className="d-flex gap-2">
                                      <Form.Control
                                        type="url"
                                        value={image.fullImageUrl}
                                        onChange={e => handleImageChange(index, 'fullImageUrl', e.target.value)}
                                        required
                                      />
                                      <ImagePreview 
                                        url={image.fullImageUrl} 
                                        alt={`Full ${index + 1}`}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                                <Col md={2} className="d-flex align-items-end">
                                  <Button
                                    variant="outline-danger"
                                    onClick={() => handleImageRemove(index)}
                                    type="button"
                                    className="mb-3"
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className="d-flex justify-content-end gap-2">
            <Button
              variant="secondary"
              onClick={() => navigate('/galleries')}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  {id ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                id ? 'Update Gallery' : 'Create Gallery'
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default GalleryForm;
