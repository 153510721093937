import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Card, Alert, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { newsService } from '../../services/api/newsService';
import ImagePreview from '../../components/common/ImagePreview';

const NewsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    content: '',
    mainImageUrl: '',
    featured: false,
    images: [],
    tags: []
  });

  const fetchNews = useCallback(async () => {
    try {
      setLoading(true);
      const news = await newsService.getNewsById(id);
      setFormData(news);
    } catch (err) {
      setError('Failed to fetch news');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchNews();
    }
  }, [id, fetchNews]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (id) {
        await newsService.updateNews(id, formData);
      } else {
        await newsService.createNews(formData);
      }
      navigate('/news');
    } catch (err) {
      setError('Failed to save news');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleImageAdd = () => {
    setFormData(prev => ({
      ...prev,
      images: [...prev.images, { imageUrl: '', index: prev.images.length }]
    }));
  };

  const handleImageRemove = (index) => {
    setFormData(prev => ({
      ...prev,
      images: prev.images.filter((_, idx) => idx !== index)
    }));
  };

  const handleTagAdd = () => {
    setFormData(prev => ({
      ...prev,
      tags: [...prev.tags, { name: '' }]
    }));
  };

  const handleTagRemove = (index) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter((_, idx) => idx !== index)
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formData.images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update indices
    const updatedItems = items.map((item, index) => ({
      ...item,
      index
    }));

    setFormData(prev => ({ ...prev, images: updatedItems }));
  };

  return (
    <Card>
      <Card.Body>
        <h2 className="mb-4">{id ? 'Edit' : 'Create'} News</h2>
        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={formData.title}
              onChange={e => setFormData(prev => ({ ...prev, title: e.target.value }))}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.description}
              onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              value={formData.content}
              onChange={e => setFormData(prev => ({ ...prev, content: e.target.value }))}
              required
            />
          </Form.Group>

          <Row>
            <Col md={9}>
              <Form.Group className="mb-3">
                <Form.Label>Main Image URL</Form.Label>
                <Form.Control
                  type="url"
                  value={formData.mainImageUrl}
                  onChange={e => setFormData(prev => ({ ...prev, mainImageUrl: e.target.value }))}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              {formData.mainImageUrl && (
                <ImagePreview url={formData.mainImageUrl} alt="Main preview" />
              )}
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Featured"
              checked={formData.featured}
              onChange={e => setFormData(prev => ({ ...prev, featured: e.target.checked }))}
            />
          </Form.Group>

          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>Additional Images</h4>
              <Button
                variant="outline-primary"
                onClick={handleImageAdd}
                type="button"
              >
                Add Image
              </Button>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="news-images">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {formData.images.map((image, index) => (
                      <Draggable
                        key={index}
                        draggableId={`image-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="mb-3"
                          >
                            <Card.Body>
                              <div {...provided.dragHandleProps} className="mb-2">
                                <i className="bi bi-grip-vertical me-2"></i>
                                Image #{index + 1}
                              </div>
                              <Row>
                                <Col md={9}>
                                  <Form.Control
                                    type="url"
                                    value={image.imageUrl}
                                    onChange={e => {
                                      const newImages = [...formData.images];
                                      newImages[index].imageUrl = e.target.value;
                                      setFormData(prev => ({ ...prev, images: newImages }));
                                    }}
                                    placeholder="Image URL"
                                    required
                                  />
                                </Col>
                                <Col md={3} className="d-flex align-items-center">
                                  <ImagePreview url={image.imageUrl} alt={`Image ${index + 1}`} />
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => handleImageRemove(index)}
                                    className="ms-2"
                                  >
                                    Remove
                                  </Button>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>Tags</h4>
              <Button
                variant="outline-primary"
                onClick={handleTagAdd}
                type="button"
              >
                Add Tag
              </Button>
            </div>

            {formData.tags.map((tag, index) => (
              <Row key={index} className="mb-2">
                <Col>
                  <Form.Control
                    type="text"
                    value={tag.name}
                    onChange={e => {
                      const newTags = [...formData.tags];
                      newTags[index].name = e.target.value;
                      setFormData(prev => ({ ...prev, tags: newTags }));
                    }}
                    placeholder="Tag name"
                    required
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-danger"
                    onClick={() => handleTagRemove(index)}
                    type="button"
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))}
          </div>

          <div className="d-flex justify-content-end gap-2">
            <Button
              variant="secondary"
              onClick={() => navigate('/news')}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  {id ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                id ? 'Update News' : 'Create News'
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default NewsForm;
