import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Card, Alert, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { comicService } from '../../services/api/comicService';

const ComicForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    annotation: '',
    mainImageUrl: '',
    featured: false,
    authors: []
  });

  const fetchComic = useCallback(async () => {
    try {
      setLoading(true);
      const comic = await comicService.getComicById(id);
      setFormData(comic);
    } catch (err) {
      setError('Failed to fetch comic');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchComic();
    }
  }, [id, fetchComic]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (id) {
        await comicService.updateComic(id, formData);
      } else {
        await comicService.createComic(formData);
      }
      navigate('/comics');
    } catch (err) {
      setError('Failed to save comic');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAuthorAdd = () => {
    setFormData(prev => ({
      ...prev,
      authors: [...prev.authors, { name: '' }]
    }));
  };

  const handleAuthorRemove = (index) => {
    setFormData(prev => ({
      ...prev,
      authors: prev.authors.filter((_, idx) => idx !== index)
    }));
  };

  const handleAuthorChange = (index, value) => {
    setFormData(prev => ({
      ...prev,
      authors: prev.authors.map((author, idx) => 
        idx === index ? { ...author, name: value } : author
      )
    }));
  };

  return (
    <Card>
      <Card.Body>
        <h2 className="mb-4">{id ? 'Edit' : 'Create'} Comic</h2>
        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={formData.title}
              onChange={e => setFormData(prev => ({ ...prev, title: e.target.value }))}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Annotation</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.annotation}
              onChange={e => setFormData(prev => ({ ...prev, annotation: e.target.value }))}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Main Image URL</Form.Label>
            <Form.Control
              type="url"
              value={formData.mainImageUrl}
              onChange={e => setFormData(prev => ({ ...prev, mainImageUrl: e.target.value }))}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Featured"
              checked={formData.featured}
              onChange={e => setFormData(prev => ({ ...prev, featured: e.target.checked }))}
            />
          </Form.Group>

          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>Authors</h4>
              <Button
                variant="outline-primary"
                onClick={handleAuthorAdd}
                type="button"
              >
                Add Author
              </Button>
            </div>

            {formData.authors.map((author, index) => (
              <Row key={index} className="mb-2">
                <Col>
                  <Form.Control
                    type="text"
                    value={author.name}
                    onChange={e => handleAuthorChange(index, e.target.value)}
                    placeholder="Author name"
                    required
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    variant="outline-danger"
                    onClick={() => handleAuthorRemove(index)}
                    type="button"
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))}
          </div>

          <div className="d-flex justify-content-end gap-2">
            <Button
              variant="secondary"
              onClick={() => navigate('/comics')}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  {id ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                id ? 'Update Comic' : 'Create Comic'
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ComicForm;
