import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Alert, Image } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { comicService } from '../../../services/api/comicService';

const ChapterView = () => {
  const { comicId, chapterId } = useParams();
  const navigate = useNavigate();
  const [chapter, setChapter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchChapter = useCallback(async () => {
    try {
      const data = await comicService.getComicChapter(comicId, chapterId);
      setChapter(data);
    } catch (err) {
      setError('Failed to fetch chapter');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, [comicId, chapterId]);

  useEffect(() => {
    fetchChapter();
  }, [fetchChapter]);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this chapter?')) {
      try {
        await comicService.deleteChapter(comicId, chapterId);
        navigate(`/comics/${comicId}`);
      } catch (err) {
        console.error('Error deleting chapter:', err);
      }
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) return <Alert variant="danger">{error}</Alert>;
  if (!chapter) return <Alert variant="warning">Chapter not found</Alert>;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Chapter {chapter.index}</h1>
        <div>
          <Button
            variant="primary"
            onClick={() => navigate(`/comics/${comicId}/chapters/${chapterId}/edit`)}
            className="me-2"
          >
            Edit Chapter
          </Button>
          <Button
            variant="danger"
            onClick={handleDelete}
            className="me-2"
          >
            Delete Chapter
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => navigate(`/comics/${comicId}`)}
          >
            Back to Comic
          </Button>
        </div>
      </div>

      <Card>
        <Card.Body>
          <div className="text-center">
            <h5 className="mb-3">Preview Image</h5>
            <Image
              src={chapter.previewImageUrl}
              alt={`Chapter ${chapter.index} preview`}
              style={{ maxWidth: '300px' }}
              className="mb-4"
              fluid
            />

            <h5 className="mb-3">Chapter Image</h5>
            <Image
              src={chapter.chapterImagePresignedUrl}
              alt={`Chapter ${chapter.index}`}
              style={{ maxWidth: '100%' }}
              fluid
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ChapterView;
