import { apiService } from './apiService';

export const galleryService = {
  getGalleries: (page = 0, size = 10, sort = 'createdAt,desc') => {
    return apiService.get(`/v1/galleries?page=${page}&size=${size}&sort=${sort}`);
  },

  getGalleryById: (id) => {
    return apiService.get(`/v1/galleries/${id}`);
  },

  createGallery: (gallery) => {
    return apiService.post('/v1/galleries', gallery);
  },

  deleteGallery: (id) => {
    return apiService.delete(`/v1/galleries/${id}`);
  }
};
