import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from '../components/layout/MainLayout';
import Login from '../pages/auth/Login';

import NewsList from '../pages/news/NewsList';
import NewsForm from '../pages/news/NewsForm';
import NewsView from '../pages/news/NewsView';

import GalleryList from '../pages/galleries/GalleryList';
import GalleryForm from '../pages/galleries/GalleryForm';
import GalleryView from '../pages/galleries/GalleryView';

import ComicList from '../pages/comics/ComicList';
import ComicView from '../pages/comics/ComicView';
import ComicForm from '../pages/comics/ComicForm';
import ChapterView from '../pages/comics/chapters/ChapterView';
import ChapterForm from '../pages/comics/chapters/ChapterForm';

import NotFound from '../pages/NotFound';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" replace />;
};

const PublicRoute = ({ children }) => {
  const { user } = useAuth();
  return !user ? children : <Navigate to="/" replace />;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route 
        path="/login" 
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } 
      />

      <Route 
        path="/" 
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<NewsList />} />

        <Route path="/news" element={<NewsList />} />
        <Route path="/news/create" element={<NewsForm />} />
        <Route path="/news/:id" element={<NewsView />} />
        <Route path="/news/:id/edit" element={<NewsForm />} />

        <Route path="/galleries" element={<GalleryList />} />
        <Route path="/galleries/create" element={<GalleryForm />} />
        <Route path="/galleries/:id" element={<GalleryView />} />
        <Route path="/galleries/:id/edit" element={<GalleryForm />} />

        <Route path="/comics" element={<ComicList />} />
        <Route path="/comics/create" element={<ComicForm />} />
        <Route path="/comics/:id" element={<ComicView />} />
        <Route path="/comics/:id/edit" element={<ComicForm />} />
        <Route path="/comics/:comicId/chapters/:chapterId" element={<ChapterView />} />
        <Route path="/comics/:comicId/chapters/create" element={<ChapterForm />} />
        <Route path="/comics/:comicId/chapters/:chapterId/edit" element={<ChapterForm />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
