import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Button, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { galleryService } from '../../services/api/galleryService';
import ImagePreview from '../../components/common/ImagePreview';

const GalleryView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGallery = useCallback(async () => {
    try {
      setLoading(true);
      const data = await galleryService.getGalleryById(id);
      setGallery(data);
    } catch (err) {
      setError('Failed to fetch gallery');
      console.error('Error fetching gallery:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchGallery();
  }, [fetchGallery]);

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!gallery) {
    return <Alert variant="warning">Gallery not found</Alert>;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>{gallery.title}</h1>
        <div>
          <Button
            variant="primary"
            onClick={() => navigate(`/galleries/${id}/edit`)}
            className="me-2"
          >
            Edit Gallery
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => navigate('/galleries')}
          >
            Back to List
          </Button>
        </div>
      </div>

      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col md={4}>
              <ImagePreview
                url={gallery.mainImageUrl}
                alt="Main gallery image"
                size="100%"
              />
            </Col>
            <Col md={8}>
              <h5>Description</h5>
              <p>{gallery.description}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <h3 className="mb-3">Gallery Images</h3>
      <Row>
        {gallery.images.map((image, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card>
              <ImagePreview
                url={image.previewImageUrl}
                alt={`Image ${index + 1}`}
                size="100%"
                thumbnail={false}
              />
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="text-muted">Views: {image.viewsCount || 0}</small>
                  {image.tags && image.tags.length > 0 && (
                    <div>
                      {image.tags.map(tag => (
                        <span
                          key={tag.id}
                          className="badge bg-secondary me-1"
                        >
                          {tag.name}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GalleryView;
