import React, { useState } from 'react';
import { Modal, Image } from 'react-bootstrap';

const ImagePreview = ({ url, alt, thumbnail = true, size = '50px' }) => {
  const [showModal, setShowModal] = useState(false);

  if (!url) return null;

  return (
    <>
      <Image
        src={url}
        alt={alt}
        style={{ 
          width: size, 
          height: size, 
          objectFit: 'cover',
          cursor: 'pointer' 
        }}
        thumbnail={thumbnail}
        onClick={() => setShowModal(true)}
      />

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{alt}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Image
            src={url}
            alt={alt}
            style={{ maxWidth: '100%', maxHeight: '80vh' }}
            fluid
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImagePreview;
