import { useState, useEffect, useCallback } from 'react';
import { Table, Button, Card, Pagination, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { newsService } from '../../services/api/newsService';
import { format } from 'date-fns';
import ImagePreview from '../../components/common/ImagePreview';

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const fetchNews = useCallback(async () => {
    try {
      setLoading(true);
      const response = await newsService.getNews(page);
      setNews(response.content);
      setTotalPages(response.totalPages);
    } catch (err) {
      setError('Failed to fetch news');
      console.error('Error fetching news:', err);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this news?')) {
      try {
        await newsService.deleteNews(id);
        await fetchNews();
      } catch (err) {
        console.error('Error deleting news:', err);
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>News</h1>
        <Button as={Link} to="/news/create" variant="primary">
          Add News
        </Button>
      </div>

      {loading ? (
        <div className="text-center p-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Card>
          <Card.Body>
            <Table responsive striped>
              <thead>
                <tr>
                  <th>Preview</th>
                  <th>Title</th>
                  <th>Tags</th>
                  <th>Views</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {news.map(item => (
                  <tr key={item.id}>
                    <td>
                      <ImagePreview
                        url={item.mainImageUrl}
                        alt={item.title}
                        size="50px"
                      />
                    </td>
                    <td>{item.title}</td>
                    <td>
                      {item.tags.map(tag => (
                        <span
                          key={tag.id}
                          className="badge bg-secondary me-1"
                        >
                          {tag.name}
                        </span>
                      ))}
                    </td>
                    <td>{item.viewsCount}</td>
                    <td>{format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}</td>
                    <td>
                      <Button
                        as={Link}
                        to={`/news/${item.id}`}
                        variant="outline-primary"
                        size="sm"
                        className="me-2"
                      >
                        View
                      </Button>
                      <Button
                        as={Link}
                        to={`/news/${item.id}/edit`}
                        variant="outline-secondary"
                        size="sm"
                        className="me-2"
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleDelete(item.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div className="d-flex justify-content-center mt-4">
              <Pagination>
                <Pagination.First
                  onClick={() => setPage(0)}
                  disabled={page === 0}
                />
                <Pagination.Prev
                  onClick={() => setPage(p => p - 1)}
                  disabled={page === 0}
                />
                {[...Array(totalPages)].map((_, idx) => (
                  <Pagination.Item
                    key={idx}
                    active={idx === page}
                    onClick={() => setPage(idx)}
                  >
                    {idx + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => setPage(p => p + 1)}
                  disabled={page === totalPages - 1}
                />
                <Pagination.Last
                  onClick={() => setPage(totalPages - 1)}
                  disabled={page === totalPages - 1}
                />
              </Pagination>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default NewsList;
