import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, Alert, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { newsService } from '../../services/api/newsService';
import ImagePreview from '../../components/common/ImagePreview';
import { format } from 'date-fns';

const NewsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchNews = useCallback(async () => {
    try {
      setLoading(true);
      const data = await newsService.getNewsById(id);
      setNews(data);
    } catch (err) {
      setError('Failed to fetch news');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this news?')) {
      try {
        await newsService.deleteNews(id);
        navigate('/news');
      } catch (err) {
        console.error('Error deleting news:', err);
      }
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) return <Alert variant="danger">{error}</Alert>;
  if (!news) return <Alert variant="warning">News not found</Alert>;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>{news.title}</h1>
        <div>
          <Button
            variant="primary"
            onClick={() => navigate(`/news/${id}/edit`)}
            className="me-2"
          >
            Edit
          </Button>
          <Button
            variant="danger"
            onClick={handleDelete}
            className="me-2"
          >
            Delete
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => navigate('/news')}
          >
            Back to List
          </Button>
        </div>
      </div>

      <Card className="mb-4">
        <Card.Body>
          <Row>
            <Col md={4}>
              <ImagePreview 
                url={news.mainImageUrl} 
                alt={news.title}
                size="100%"
              />
            </Col>
            <Col md={8}>
              <h5>Details</h5>
              <p><strong>Created:</strong> {format(new Date(news.createdAt), 'dd/MM/yyyy HH:mm')}</p>
              <p><strong>Views:</strong> {news.viewsCount}</p>
              <p><strong>Featured:</strong> {news.featured ? 'Yes' : 'No'}</p>

              <h5>Description</h5>
              <p>{news.description}</p>

              <h5>Content</h5>
              <div dangerouslySetInnerHTML={{ __html: news.content }} />

              <h5>Tags</h5>
              <div>
                {news.tags?.map(tag => (
                  <span 
                    key={tag.id} 
                    className="badge bg-secondary me-1"
                  >
                    {tag.name}
                  </span>
                ))}
              </div>
            </Col>
          </Row>

          <h5 className="mt-4">Additional Images</h5>
          <Row>
            {news.images?.map((image, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
                <Card>
                  <ImagePreview 
                    url={image.imageUrl} 
                    alt={`Image ${index + 1}`}
                    size="100%"
                    thumbnail={false}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default NewsView;
