import axiosInstance from './axios.config';

export const apiService = {
  get: async (endpoint, config = {}) => {
    const response = await axiosInstance.get(`/admin${endpoint}`, config);
    return response.data;
  },

  post: async (endpoint, data = {}, config = {}) => {
    const response = await axiosInstance.post(`/admin${endpoint}`, data, config);
    return response.data;
  },

  put: async (endpoint, data = {}, config = {}) => {
    const response = await axiosInstance.put(`/admin${endpoint}`, data, config);
    return response.data;
  },

  delete: async (endpoint, config = {}) => {
    const response = await axiosInstance.delete(`/admin${endpoint}`, config);
    return response.data;
  }
};
